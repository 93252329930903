// @flow
import React from 'react';
import type { Node } from 'react';
import type { Result } from '../../models/results/Result';
import moment from 'moment';
import ShipIcon from '../../assets/images/views/ships/ship_icon.svg';
import { default as UserNoImage } from '../../assets/images/views/users/no-image.png';
import styled from 'styled-components';
import { viewSp } from '../../lib/Mixins';
import { ShipShowUrl } from '../../lib/ShipRoutes';

type Props = {
  result: Result,
  className: ?string,
  withComment: ?boolean,
};

interface DefaultProps {
  className: string;
  withComment: boolean;
}

export default class ResultCard extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    className: 'col-6 col-md-3',
    withComment: true,
  };

  renderShipOrArea(result: Result): Node {
    // NOTE: 釣船が非公開の時は、エリア名を出している。非リンク化の釣船を出すこともできたが、
    //       非リンク化と見た目が変わらないため、エリア名を出す方がユーザーライクだと判断した。
    if (result.ship != null && result.ship.publication_status == 'published') {
      const name = result.ship.name;
      return (
        <a
          href={`${ShipShowUrl(result.ship.id)}`}
          className="link-hover"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ShipOrAreaName>
            <img src={ShipIcon} /> {name}
          </ShipOrAreaName>
        </a>
      );
    } else if (result.area != null) {
      return (
        <a
          href={`/areas/${result.area.id}`}
          className="link-hover"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ShipOrAreaName>
            <i className="fa fa-map" /> {result.area.name}
          </ShipOrAreaName>
        </a>
      );
    } else if (result.postal_code != null) {
      return <ShipOrAreaName>{result.postal_code.name}</ShipOrAreaName>;
    } else {
      return <ShipOrAreaName>未選択</ShipOrAreaName>;
    }
  }

  onClick() {
    // $FlowFixMe
    gtag('event', 'click', {
      event_category: 'ToCatch',
      event_label: 'result_card',
    });
  }

  render(): Node {
    const { result, className, withComment } = this.props;

    return (
      <div className={className} key={result.id}>
        <div className="results thumb card">
          <a
            href={`/catches/${result.id}`}
            onClick={this.onClick.bind(this)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ThumbnailContainer>
              <div className="thumbnail">
                <ResultImgage
                  src={result.thumb_url}
                  alt={
                    result.fish != null ? `${result.fish.name}の釣果` : '釣果'
                  }
                  loading="lazy"
                />
              </div>
              <UserInfo>
                <img
                  src={result.user.thumb_url}
                  alt="Image Not Found"
                  onError={e => (e.target.src = UserNoImage)}
                />
                <UserName>
                  {result.user.name}
                  {result.user.is_official && (
                    <i className="fa fa-check-square text-approved ml-5" />
                  )}
                </UserName>
              </UserInfo>
            </ThumbnailContainer>

            <div className="card-body body-color">
              <FishInfoWrapper className="detail row">
                <div className="col-8 body-color">
                  {result.fish != null ? (
                    <a
                      href={`/fishes/${result.fish.id}`}
                      className="link-hover"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FishInfo>{result.fish.name}</FishInfo>
                    </a>
                  ) : (
                    <FishInfo>未選択</FishInfo>
                  )}
                </div>
                <div className="col-4 text-right">
                  {result.size && (
                    <FishInfo>
                      <small>
                        {result.size}
                        cm
                      </small>
                    </FishInfo>
                  )}
                </div>
              </FishInfoWrapper>

              <ShipOrAreaNameWrapper className="detail row">
                <div className="col-12">{this.renderShipOrArea(result)}</div>
              </ShipOrAreaNameWrapper>

              <CaughtInfoWrapper className="row">
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <CaughtInfo>
                    {moment(result.caught_at).format('YYYY.MM.DD')}
                  </CaughtInfo>
                  <CaughtInfo>
                    {result.fishinglog_published_results_count > 1 && (
                      <span>
                        {result.fishinglog_published_results_count}
                        釣果
                      </span>
                    )}
                  </CaughtInfo>
                </div>
              </CaughtInfoWrapper>

              {result.publish_comment && (
                <PublishComment withComment={withComment}>
                  <p>{result.publish_comment}</p>
                </PublishComment>
              )}
              <SocialCount>
                <span>
                  <i className="fa fa-thumbs-o-up mr-1" />
                  {result.cached_votes_up}
                </span>
                {result.page_views != null && result.page_views > 10 && (
                  <span>
                    <i className="fa fa-eye mr-1 ml-10" />
                    {result.page_views}
                  </span>
                )}
                {result.publish_comments_count > 0 && (
                  <span className="float-right">
                    <i className="fa fa-comment-o mr-1" />
                    {result.publish_comments_count}
                  </span>
                )}
              </SocialCount>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

const ThumbnailContainer = styled.div`
  position: relative;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;

  img {
    background-color: white;
    border-radius: 50%;
    border: 2px solid white;
    margin: 0 5px;
    height: 27px;
    width: 27px;

    ${viewSp} {
      height: 21px;
      width: 21px;
      line-height: 1;
    }
  }
`;

const ResultImgage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const UserName = styled.div`
  color: white;
  font-size: 16px;
  font-weight: bold;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ${viewSp} {
    font-size: 11px;
  }
`;

const WrapperBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FishInfoWrapper = styled(WrapperBase)`
  height: 24px;
  margin-top: 8px;

  ${viewSp} {
    height: 16px;
    margin-top: 4px;
  }
`;

const FishInfo = styled.div`
  font-size: 18px;
  font-weight: bold;

  small {
    font-size: 16px;
    font-weight: bold;
  }

  ${viewSp} {
    font-size: 12px;

    small {
      font-size: 11px;
    }
  }
`;

const ShipOrAreaNameWrapper = styled(WrapperBase)`
  height: 24px;
  margin-top: 4px;

  ${viewSp} {
    height: 16px;
    margin-top: 2px;
  }
`;

const ShipOrAreaName = styled.div`
  color: #6c757d;
  font-size: 16px;
  font-weight: bold;

  ${viewSp} {
    font-size: 11px;
  }
`;

const CaughtInfoWrapper = styled(WrapperBase)`
  margin-top: 8px;
  line-height: 1;

  ${viewSp} {
    margin-top: 4px;
  }
`;

const CaughtInfo = styled.div`
  color: #6c757d;
  font-size: 12px;

  ${viewSp} {
    font-size: 11px;
  }
`;

const PublishComment = styled.div`
  background: #f8f8f8;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 12px;
  padding: 8px;

  p {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    padding: 0;
  }

  ${viewSp} {
    font-size: 11px;
    margin-top: 8px;
    display: ${props => (props.withComment ? '-webkit-box' : 'none')};
  }
`;

const SocialCount = styled.div`
  font-size: 12px;
  margin-top: 12px;

  ${viewSp} {
    margin-top: 4px;
  }
`;
