// @flow
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import Image from '../../assets/images/views/commons/interstitial_banner_madai.webp';
import { viewPc, viewSp } from '../../lib/Mixins';

const InterstitialBannerLimited = (): Node => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    function getCookie(name) {
      const value = '; ' + document.cookie;
      const parts = value.split('; ' + name + '=');
      if (parts.length === 2)
        return parts
          .pop()
          .split(';')
          .shift();
    }

    function setCookie(name, value, days) {
      let expires = '';
      if (days) {
        const date = new Date();
        // 有効期限を設定
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
      }
      document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    // Cookieを使って新規セッションかどうかをチェック
    const isSeen = getCookie('interstitialSeen');

    // フェードインさせるために遅延させる（重いページが多いので少し長めに設定）
    setTimeout(() => {
      if (!isSeen) {
        setVisible(true);
        setCookie('interstitialSeen', 'true', 1); // 1日の有効期限を設定
      }
    }, 750);
  }, []);

  return (
    <Interstitial visible={visible} onClick={() => setVisible(false)}>
      <StyledLink
        href={
          'https://ships.anglers.jp/coupons/XGRTB9A0?utm_source=anglers&utm_medium=web_interstitial_top&utm_campaign=20250115_1000'
        }
        target="_blank"
        className="ga4_interstitial"
      >
        <img src={Image} alt="キャンペーンバナー" />
      </StyledLink>
    </Interstitial>
  );
};

const Interstitial = styled.div`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'initial' : 'none')};
  transition: opacity 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background: rgba(23, 26, 28, 0.6);
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  ${viewPc} {
    max-width: 70%;
    max-height: 100vh;
  }

  ${viewSp} {
    max-width: 100%;
    max-height: 70vh;
  }

  img {
    border-radius: 3px;
    max-width: 600px;
    width: 100%;
    max-height: 500px;
    height: 100%;
  }
`;

export default InterstitialBannerLimited;
